/*  애니메이션 추가 */
@keyframes markerAnimation {
  0% {
    transform: translateY(-20px);
    opacity: 0.2;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
